<template>
    <div class="container-geral">
        <div class="container">
            <form action="" @submit.prevent="validaUsuario" id="formLogin">
                <div class="container-usuario">
                    <h1 class="label-container">Login Administrativo</h1>
                    <div class="label-usuario">
                        <span class="usuario"> Usuário * </span>
                    </div>
                    <div class="input-usuario">
                        <input :class="{ 'input-invalid': isInvalid }" type="text" v-model="usuario" required />
                    </div>
                </div>
                <div class="container-senha">
                    <div class="label-senha">
                        <span class="senha"> Senha * </span>
                    </div>
                    <div class="input-senha">
                        <input :class="{ 'input-invalid': isInvalid }" type="password" v-model="senha" required />
                    </div>
                </div>
                <small v-if="isInvalid" class="usuario-invalido">Usuário ou senha inválidos</small>
            </form>
        </div>
        <msg-error v-if="showAlert" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import HTTP from '../http/http.js';
import MsgError from '../components/MsgError.vue';

export default {
    data() {
        return {
            usuario: '',
            senha: '',
            isInvalid: false,
            showAlert: false,
            msgE: 'Login inválido! Verifique com o suporte.',
        };
    },

    components: {
        MsgError,
    },

    methods: {
        validaUsuario() {
            if (this.usuario == '' || this.senha == '') {
                this.isInvalid = true;
            }

            let data = {
                nome: this.usuario,
                senha: this.senha,
            };

            HTTP.post('login', data)
                .then((response) => {
                    let usuario = response.data;

                    if (usuario.nome == 'admcsd') {
                        sessionStorage.setItem('usuario', usuario.nome);
                        sessionStorage.setItem('loja', usuario.loja);

                        this.$router.push({ name: 'adm' });
                    } else {
                        this.showAlert = true;
                        this.fechaAlert();
                    }
                })
                .catch(() => {
                    this.isInvalid = true;
                });
        },

        fechaAlert() {
            setTimeout(() => {
                this.showAlert = false;
            }, 1800);
        },
    },
};
</script>

<style scoped>
.container-geral {
    display: grid;
}

.container {
    padding: 20px;
    box-shadow: 0 0 5px #cdcdcd;
    border-radius: 10px;
    background-color: #fefefe;
    margin: 100px 15%;
}

.label-container {
    margin-bottom: 10px;
    color: var(--verde);
    font-weight: 600;
}

form div {
    padding: 5px 0;
}

form div div span {
    font-size: 0.8rem;
    color: #5f5e5e;
}

form div div input {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

form div div input:focus {
    outline-color: var(--vermelho);
}

.usuario-invalido {
    font-size: 0.7rem;
    color: red;
}

.input-invalid {
    border-color: red;
}

.btn-reset {
    border: 1px solid #cdcdcd;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 10px;
    color: var(--verde);
    font-weight: 500;
    margin: 0 70px;
    cursor: pointer;
}

.btn-reset:hover {
    background-color: var(--verde);
    color: #fff;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 0;
    top: 20%;
    right: 0;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 15px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn {
    margin-top: 8%;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 5px;
    background-color: var(--verde);
    color: #fff;
}

@media (max-width: 327px) {
    .cancel-msg {
        font-size: 16px !important;
    }

    .tela-mensagem h3 {
        text-align: center;
    }

    .cancel-btn button {
        font-size: 16px;
    }
}
</style>
