<template>
    <transition name="fade">
        <div class="popUp">
            <div class="tela-mensagem">
                <div class="error">
                    <img src="../assets/img/alert-white.svg" alt="imagem de check" />
                </div>
                <div class="error-msg">
                    <p>{{ mensagemError }}</p>
                    <button class="error-btn" @click="closeError()">Ok</button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        mensagemError: {
            type: String,
        },
    },

    methods: {
        closeError() {
            this.$emit('closeError');
        },
    },
};
</script>

<style scoped>
.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 5%;
    top: 15%;
    bottom: 15%;
    right: 5%;
    margin: 18px;
    background-color: #fff;
    border-radius: 6px;
}

.error {
    background-color: var(--vermelho);
    padding: 10px 20px;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.error img {
    width: 90px;
    margin-top: 40px;
}

.error-msg {
    padding: 10px;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.error-msg p {
    margin-top: 10px;
}

.error-btn {
    padding: 5px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 10px;
    background-color: var(--vermelho);
    color: #fff;
    cursor: pointer;
}

.error-btn:hover {
    background-color: #ce4141;
    position: relative;
    top: 5px;
}

.error-msg p {
    font-size: 16px;
}

@media (min-width: 768px) {
    .error-msg p {
        font-size: 22px;
    }
}
</style>
